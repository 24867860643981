
html, body {
  padding: 0;
  margin: 0;
}
.main-body {
    width: 100%;
    height: 100vh;
    position: relative;
    &, & * {
      box-sizing: border-box;
    }
    .bgimage-main {
        width: 100%;
        height: 100%;
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
        }
    }
    .qr-code-img {
        width: 20%;
        height: auto;
        position: absolute;
        bottom: 0.3%;
        right: 5%;
        img {
          width: 100%;
          height: 100%;
        }
    }
}

