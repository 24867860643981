
.boom-main {
    &, & * {
        box-sizing: border-box;
    }
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
}

